.incompatibility {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  padding: 50px 25px;
  position: relative;
  text-align: center;
  box-sizing: border-box;
}
.incompatibility::after {
  background-color: #4d9dff;
  bottom: 0;
  content: "";
  height: 50vh;
  position: absolute;
  width: 100%;
  z-index: -1;
  box-sizing: border-box;
}

.incompatibility__content {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.incompatibility__logo {
  margin-bottom: 25px;
}

.incompatibility__heading {
  color: #4d9dff;
  font-family: "ATT Aleck Sans", -apple-system, BlinkMacSystemFont, Helvetica,
    Arial, sans-serif;
  font-size: 35px;
  margin-bottom: 50px;
  text-transform: none;
  font-weight: 500;
  font-style: normal;
  line-height: 43px;
}

.incompatibility__code {
  align-items: center;
  background-color: #fff;
  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.15);
  display: flex;
  height: 200px;
  justify-content: center;
  margin-bottom: 50px;
  width: 200px;
  height: 200px;
  padding: 1rem;
}

.incompatibility__code img{
  width: 100%;
  height: 100%;
}

.incompatibility__copy {
  margin-top: 0;
  color: #fff;
  max-width: 500px;
  font-size: 22.5px;
  line-height: 33.75px;
  font-weight: normal;
}
