.cookie {
  display: none;
}

.modalBackground {
  background: #fff;
  height: 16rem;
}

.home {
  background-size: cover;
  background-image: url('../assets/images/LoadingBG.jpg');
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  justify-content: space-around;
  flex-wrap: nowrap !important;
}

.alert {
  font-weight: bold;
  font-size: 1.2rem;
  margin: 0;
  padding: 0;
  color: #0066B2;
}

.accept {
  width: 65%;
  text-align: center;
  font-size: 1rem;
  margin-top: 0.4rem;
  padding: 0;
  color: #71CFFF;
}

.static {
  height: 32vh;
}

@media screen and (orientation: landscape) {
  .home {
    height: 100%;
    width: 100%;
    justify-content: flex-start;

    .content {
      height: 80% !important;
      width: auto;
      padding: 20px 0;

      p {
        padding: 0 180px !important;
        font-size: 0.8rem;
      }

      .allow-camera-access {
        font-size: 0.5rem !important;
      }
    }

    .cta {
      margin: 1rem !important;
    }

    .button {
      font-size: .75rem;
      height: 50px;
      width: 180px;
      margin: -10px auto 30px;
    }
  }
}