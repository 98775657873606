$blue: #0066B2;

.instructions {
  background-image: url('../assets/images/InstructionsBG.png');
  background-size: cover;
  background-position: center;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: 0;
  transition: 0.5s;
  
  @media (orientation: landscape) {
    background-image: url('../assets/images/InstructionsBGLandscape.png');
  }
  &.mounted {
    opacity: 1;
    z-index: 10;
  }
  
  .instruction-screen {
    h2 {
      margin-top: -15px;
      color: #EE7943;
      font-weight: 900;
    }
    
    @media (orientation: landscape) {
      margin-top: 20px;

      #reposition {
        width: 90px;
        margin: 0 auto;
      }
    }
  }

  p {
    text-align: center;
    font-size: 1.125rem;
    padding: 0 20%;
    font-family: 'ATT Aleck Sans';
    font-weight: 500;
    
    @media (orientation: landscape) {
      font-size: 1rem;
      padding: 0 30%;
    }
  }

  

  .image-container {
    width: 32%;
    margin: 0 auto 25px;
    
    @media (orientation: landscape) { 
      margin: 8px 0;
      width: 70%;
    }

    &.flick {
      width: 37%;
    }

    &.health {
      width: 47%;
    }

    img {
      width: 100%;
      height: auto;
      
      @media (orientation: landscape) { 
        // width: 33px;
        // height: 33px;
        // margin: 0;
      }
    }



    // .svgContainer {
    //   margin: 10px -20px 0 0;
    //   @media (orientation: landscape) { 
    //     transform: scale(0.9);
    //     margin: 0 -22px 0 0;
    //   }
    // }

    // p {
    //   font-size: 2.5em;
    //   font-weight: 800;
    //   margin: 0 5px;
      
    //   @media (orientation: landscape) { 
    //     margin-top: 8px;
    //     font-size: 2em;
    //   }
    // }
  }

  .icon-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    
    @media (orientation: landscape) {
      justify-content: center;
    }

    .icon-container {
      width: 33%;
      
      @media (orientation: landscape) {
        width: 20%;
      }

      p {
        margin-top: 10px;
        padding: 0 25px;
      }
    }

    #speed {
      width: 100px;
      margin: 0 auto;
      transform: scale(-1);
    }

    #shield {
      width: 60px;
      margin: 0 auto;
    }

    #more-time {
      width: 90px;
      margin: 0 auto;
    }
  }

  #skip {
    width: 100%;
    font-size: 1rem;
    display: block;
    position: absolute;
    width: auto;
    left: 50%;
    font-family: 'ATT Aleck Sans';
    transform: translateX(-50%);
    bottom: 7%;
    color: $blue;
    
    @media (orientation: landscape) {
      position: absolute;
      left: auto;
      width: auto;
      right: 20%;
      bottom: 17.5%;
    }

    // @media (max-height: 300px) {
    //   bottom: 15%;
    // }

  }

  .button {
    position: relative;
    top: 60px;
    left: 50%;
    transform: translateX(-50%);
  }

  .skip-button {
    position: absolute;
    font-weight: 900;
    color: #0066B2;
    bottom: -100px;
    left: 50%;
    transform: translateX(-50%);
    
    @media (orientation: landscape) {
      left: auto;
      bottom: 0;
      right: 70px;
      transform: none;
      text-align: right;
    }
  }
}

.hide {
  display: none;
}


.slick-dots {
  bottom: 35px !important;

  @media (orientation: landscape) {
    bottom: -15px !important;
  }

  li {
    margin: 0 !important;
    button {
      &:before {
        content: '' !important;
        height: 8px !important;
        width: 8px !important;
        background: #fff !important;
        border-radius: 50%;
        opacity: 1 !important;
        border: 1px solid $blue !important;
        margin: 5px;
      }
    }
  }
  
  .slick-active {
    button {
      &:before {
        background: $blue !important;
      }
    }
  }
}

.slick-prev {
  display: none !important;
}


@media (orientation: landscape) {
  .slick-list {
    margin-top: -80px;
  }

  .instructions {
    .button-container {
      position: absolute;
      width: 100%;
      height: 70px;
      display: block;
      bottom: -70px;
  
      .button {
        position: absolute;
        bottom: 0;
        top: auto;
        margin: 0;
      }
    }
  }

}