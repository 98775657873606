/***** Fonts *****/
@font-face {
    font-family: 'ATT Aleck Cd';
    src: url('../assets/fonts/ATTAleckCd/ATTAleckCd-Light.woff2') format('woff2'),
        url('../assets/fonts/ATTAleckCd/ATTAleckCd-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'ATT Aleck Cd';
    src: url('../assets/fonts/ATTAleckCd/ATTAleckCd-Medium.woff2') format('woff2'),
        url('../assets/fonts/ATTAleckCd/ATTAleckCd-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'ATT Aleck Cd';
    src: url('../assets/fonts/ATTAleckCd/ATTAleckCd-Black.woff2') format('woff2'),
        url('../assets/fonts/ATTAleckCd/ATTAleckCd-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: 'ATT Aleck Cd';
    src: url('../assets/fonts/ATTAleckCd/ATTAleckCd-BlackItalic.woff2') format('woff2'),
        url('../assets/fonts/ATTAleckCd/ATTAleckCd-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'ATT Aleck Sans';
    src: url('../assets/fonts/ATTAleckSans/ATTAleckSans-Medium.woff2') format('woff2'),
        url('../assets/fonts/ATTAleckSans/ATTAleckSans-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'ATT Aleck Sans';
    src: url('../assets/fonts/ATTAleckSans/ATTAleckSans-Regular.woff2') format('woff2'),
    url('../assets/fonts/ATTAleckSans/ATTAleckSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'ATT Aleck Sans';
    src: url('../assets/fonts/ATTAleckSans/ATTAleckSans-Bold.woff2') format('woff2'),
        url('../assets/fonts/ATTAleckSans/ATTAleckSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'ATT Aleck Sans';
    src: url('../assets/fonts/ATTAleckSans/ATTAleckSans-BoldItalic.woff2') format('woff2'),
        url('../assets/fonts/ATTAleckSans/ATTAleckSans-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

// Sans Black
@font-face {
    font-family: 'ATT Aleck Sans';
    src: url('../assets/fonts/ATTAleckSans/ATTAleckSans-Black.woff2') format('woff2'),
        url('../assets/fonts/ATTAleckSans/ATTAleckSans-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'ATT Aleck Sans';
    src: url('../assets/fonts/ATTAleckSans/ATTAleckSans-BlackItalic.woff2') format('woff2'),
        url('../assets/fonts/ATTAleckSans/ATTAleckSans-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
}



@font-face {
    font-family: 'ATT Aleck Sans';
    src: url('../assets/fonts/ATTAleckSans/ATTAleckSans-Italic.woff2') format('woff2'),
        url('../assets/fonts/ATTAleckSans/ATTAleckSans-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'ATT Aleck Sans';
    src: url('../assets/fonts/ATTAleckSans/ATTAleckSans-Light.woff2') format('woff2'),
        url('../assets/fonts/ATTAleckSans/ATTAleckSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'ATT Aleck Sans';
    src: url('../assets/fonts/ATTAleckSans/ATTAleckSans-LightItalic.woff2') format('woff2'),
        url('../assets/fonts/ATTAleckSans/ATTAleckSans-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}
