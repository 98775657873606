video.curbsidega {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
video.curbsidega.has-fade {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}
