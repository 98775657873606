.ScrollbarsCustom {
    position: relative;
    width: 100%;

    &:not(.native) {
      width: 100vw;
      height: 100vh;

      & > .ScrollbarsCustom-Wrapper {
        top: 0;
        left: 0;
        right: 0;
        bottom: 10px;

        &:before, &:after{
            position: absolute;
            content: '';
            left: 0;
            height: 15px;
            width: 100%;
            z-index: 1;
            pointer-events: none;
        }
        &:before{
            top: 0;
            background: linear-gradient(rgba(77,157,255,1), rgba(77,157,255,0));
        }
        &:after{
            bottom: 0;
            background: linear-gradient(rgba(77,157,255,0), rgba(77,157,255,1));
        }
        

        & > .ScrollbarsCustom-Scroller {
          display: block;

          & > .ScrollbarsCustom-Content {
            padding: 0.05px;
            width: 100%;
            height: 100%;
          }
        }
      }

      & > .ScrollbarsCustom-Track {
        position: absolute;
        border-radius: 0.5rem;

        & > .ScrollbarsCustom-Thumb {
          background: #fff;
          cursor: pointer;
          border-radius: 0.5rem;

          &:hover,
          &.dragging {
            background: #9ed6ff;
          }
        }
      }

      & > .ScrollbarsCustom-TrackY {
        width: 10px;
        height: calc(100% - 30px);
        top: 10px;
        right: 0.25rem;
        background: #1257a3;

        & > .ScrollbarsCustom-ThumbY {
          width: 100%;
        }
      }

      &.trackYVisible {
        & > .ScrollbarsCustom-Wrapper {
          right: 1.5rem;
        }
      }
    }
  }