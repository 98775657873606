@import '../index.scss';

.container {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  display: block;
  padding: 0px 0;
  background-size: cover;
  background-position: center;
}

// ----------------- Colored Background ----------------- //
.overlay {
  pointer-events: none;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255, .8);
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  transition: 0.5s;

  &.pause {
    z-index: 1;
    background: transparent;
    background-image: url('../assets/images/InstructionsBG.png');
    background-size: cover;
  }

  &.hide{
    opacity: 0;
  }
}

.content {
  position: relative;
  width: 100%;
}

.title {
  width: 100%;
  margin: 40px auto;
  text-align: center;

  @media (orientation: landscape) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.copy {
  text-align: center;
  width: 75%;
  margin: 0 auto;

  strong {
    display: block;
  }
  
  @media (orientation: landscape) {
    width: 40%;
    margin: 25px auto 0;
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  
  @media (orientation: landscape) {
    width: 55%;
    margin: 0 auto;
    flex-direction: row;
  }

  button {
    margin: 10px 0;
  }
}

// ----------------- Close Button ----------------- //
.close {
  width: 127px;
  height: 80px;
  position: absolute;
  z-index: 1000;
  top: 0;
  right: 0;
  background-image: url('../assets/images/CloseButton.png');
  background-size: cover;
  background-position: center;
}

// ----------------- Game Over ----------------- //
.game-over {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 3;
  background-image: url('../assets/images/BG.png');
  background-position: center;
  background-size: cover;

  .content {
    .title {
      @media (orientation: landscape) {
        margin: 0;
      }
    }
  }

  .score {
    color: #000;
    padding: 40px 0;
    
    .column {
      display: flex;
      flex-direction: column;
    }
    @media (orientation: landscape) {
      padding: 20px 0 0;
    }
    
    span {
      text-transform: uppercase;
      font-family: 'ATT Aleck Sans';
      font-size: 1rem;
      margin-bottom: -10px;
      text-align: center;
      font-weight: 900;
    }

    h3 {
      font-size: 3.5rem;
      margin: 0;

      @media (orientation: landscape) {
        margin-bottom: -11px;
      }

      // @media (max-height: 300px) {
      //   font-size: 2.75rem;
      //   margin: 5px 0 -10px;
      // }
    }
  }
  .buttons {
    margin-top: -40px;

    button {
      width: 34vw;
    }

    @media (orientation: landscape) {
      margin-top: 0;
  
      button {
        width: 180px;
        padding: 0;
        margin: 0 20px;
      }
    }
  }
}


// ----------------- Pause Screen ----------------- //
.game-paused {
  width: 100%;
  height: 100%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 3;

  h1 {
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    padding: 0 20px;

    // @media (max-height: 300px) {
    //   font-size: 6rem;
    // }
  }

  .buttons {
    margin-top: 40px;

    button {
      width: 20vw;
    }
    
    @media (orientation: landscape) {
      margin: 50px auto 0;
      width: 85%;
      
      button {
        width: 20vw;
        margin: 0 5px;
        padding: 0;
        top: auto;
      }
    }
    
    // @media (max-height: 300px) {
    //   margin: -60px auto 30px;
    // }
  }
}

.arrow-container{
  height: 40px;
  position: absolute;
  width: 100%;
  top: 40%;
  left: 0;
  transform: translateY(-50%);
  img.arrow{
    position: absolute;
    top: 0;
    height: 40px;
    width: auto;
    opacity: 0;
    transition: opacity .3s;
    &.show{
      opacity: 1;
    }
    &.left{
      left: 10px;
      transform: scaleX(-1);
    }
    &.right{
      right: 10px;
    }
  }
}

#two-thumbs {
  width: auto;
  height: 80px;
  @media (orientation: landscape) {
    height: 70px;
    margin: 0 auto;
  }
}

.landing-screen {
  position: absolute;
  background-color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999;
  opacity: 0;
  transition: 0.5s;
  background-image: url('../assets/images/BG.png');
  background-size: cover;
  background-position: center;
  
  &.football {
    background-image: url('../assets/images/FootballBG.png');

    h1 {
      span {
        margin-left: -24px;
      }
    }
  }

  &.mounted {
    opacity: 1;
  }
  
  p {
    margin: 40px auto 30px;
  }
  
  @media (orientation: landscape) {
    background-image: url('../assets/images/SpeedwayBG.png');
    justify-content: space-around;

    .button {
      top: auto;
    }

    p {
      margin: 0 auto;
      font-size: 1rem;
      padding: 0 13%;
    }

    img {
      width: 70px;
      height: 70px;
      display: inline-block;
      margin: -15px 0;
    }

    .button { 
      font-size: .875rem;
      height: 50px;
      width: 110px;
    }
  }
}

#feet {
  padding: 8px;
  background: rgba(0, 161, 219, 0.8);
  border-radius: 50%;
  border: 4px solid #fff;
  line-height: 1;
  font-weight: 800;
  margin: -2px 0 0 5px;
  font-size: 12px;
}

.rotate {
  background: #fff;
  height: 100%;
  
  .content {
    z-index: 1;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  h1 {
    font-size: 4rem;
    margin-top: -20px;

    span {
      display: block;
      font-size: 2.4rem;
      margin-top: 9px;
      margin-left: -16px;
    }
  }
  
  img {
    width: 200px;
    margin: 50px auto;
  }

  h4 {
    text-transform: uppercase;
    color: #000;
    font-size: 1rem;
    padding: 0 30px;
    text-align: center;
    font-family: 'ATT Aleck Sans';
    font-weight: 900;
    font-style: normal;
    font-size: 14px;
  }

  @media (orientation: landscape) {
    .title {
      margin-bottom: -40px;
      flex-direction: row;
    }
    h1 {
      &:last-child {
        margin-left: 15px;
      }
    }
    h4 {
      padding: 0 30%;
    }
    img {
      display: none;
    }
  }
}

.wave-screen {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h2, h3 {
    font-weight: 400;
    letter-spacing: -4.5px;
    text-transform: none;
  }
}


.image-with-icon-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 15px 0;
  }
  
  .image-with-icon{
    display: flex;
    flex-direction: row;
    height: 36px;
    justify-content: center;
    align-items: center;
    margin: 0 4px;

    img {
      height: 100%;
      margin: 0 5px;
    }

    #timer {
      margin-top: -5px;
      height: 41px;
    }

    p {
      font-size: 2rem;
      font-weight: 800;
      margin: 0;
      padding: 0;
    }
  }
  .image-container {
    width: 85%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin: -15px 0 -15px;
    
    @media (orientation: landscape) { 
      margin: 8px 0;
      width: 70%;
    }

    img {
      width: 100px;
      height: 100px;
      margin: 5px 0 0;
      
      @media (orientation: landscape) { 
        width: 33px;
        height: 33px;
        margin: 0;
      }
    }

    .guage {
      width: 220px;
      height: 90px;
      
      &.health {
        width: 200px;
        height: 75px;
      }
    }

    .svgContainer {
      margin: 10px -20px 0 0;
      @media (orientation: landscape) { 
        transform: scale(0.9);
        margin: 0 -22px 0 0;
      }
    }

    p {
      font-size: 2.5em;
      font-weight: 800;
      margin: 0 5px;
      
      @media (orientation: landscape) { 
        margin-top: 8px;
        font-size: 2em;
      }
    }
  }

  .icon-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    // margin-top: 40px;

    .icon-container {
      width: 33%;

      p {
        margin-top: 0px;
        padding: 0 25px;
      }
    }

    #speed {
      width: 130px;
      margin: 0 auto;
      transform: scale(-1);
    }

    #shield {
      width: 80px;
      margin: 0 auto;
    }

    #more-time {
      width: 90px;
      margin: 0 auto;
    }
  }
