

.ios-loading-spinner {
  width: 35px;
  height: 35px;
  -webkit-animation-name: ios-loading-spinner-rotate;
  -webkit-animation-duration: 1.5s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  transform: rotate(0deg);
}

@keyframes ios-loading-spinner-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
