@import './styles/fonts';
@import './styles/instruction-screens';
@import './styles/hud';
@import './styles/landing-experience';
@import './styles/homepage';
@import './styles/loading-screens';

/***** Colors *****/

$red: #c0504d;
$blue: #0066B2;


html, body, #root, .app {
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: 'ATT Aleck Sans', sans-serif;
  color: #fff;
}

h1, h2, h3, h4 {
  font-weight: normal;
  text-align: center;
}

h1 {
  font-size: 4rem;
  font-family: 'ATT Aleck Cd', sans-serif;
  font-weight: 900;
  font-style: italic;
  color: $blue;
  text-transform: uppercase;
  line-height: 0.8;
  text-align: center;
  margin: 0;
  
  span{
    margin-left: -14px;
  }
}

h2 {
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 3.33px;
}

h3 {
  font-size: 2rem;
  font-weight: 800;
}

p {
  font-size: 1.2rem;
  font-family: 'ATT Aleck Sans', sans-serif;
  font-weight: 500;
  color: #000;
}

.button-md,.button,.MuiButtonBase-root.button {
  text-transform: uppercase;
  border: none;
  position: relative;
  font-family: 'ATT Aleck Sans', sans-serif;
  font-weight: 900;
  margin: 15px auto;
  padding: 0 12vw;
  font-size: 1.2rem;
  letter-spacing: 1px;
  color: #fff;
  background-color: #0066B2;
  width: auto;
  height: 3.9375rem;
  box-shadow: 0 9px 16px 0 rgba(0, 102, 178, 0.6);
  border-radius: 33px;
  text-align: center;
  
  @media (orientation: landscape) {
    width: 120px;
    top: 0;
    font-size: 0.8rem;
    padding: 0;
    box-shadow: 0 5px 10px 0 rgba(4, 86, 131, 0.69);
    height: 45px;
    display: block;
    margin: 25px auto;
  }
}

html.launched {
  &, body, #root, .app {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  #root {
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    z-index: 10;
  }
  .app{
    position: absolute;
    z-index: 2;
  }
}

// 8th wall motion sensor dialog
.button-primary-8w {
  background-color: #009fdb;
}

#ar-canvas {
  position: absolute;
  top: 0;
}

.launched {
  #ar-canvas {
    position: relative;
    top: 0;
  }
}

.absolute-fill {
  height: 110%;
}

.logo {
  height: auto;
  width: 50%;
  margin: 40px auto 10%;
  
  @media (orientation: landscape) {
    height: 2rem;
    width: auto;
    margin: 30px auto 10px;
  }
  
  @media (max-width: 321px) {
    height: 3.75rem;
    width: auto;
  }
}