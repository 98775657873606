$bottom-height: 223px;
$animation-function-quicktour: cubic-bezier(0.645, 0.045, 0.355, 1.000);
$animation-duration-quicktour: 1400ms;
$animation-function-remote-in: cubic-bezier(0.645, 0.045, 0.355, 1.000);
$animation-function-remote-out: ease-in;
$animation-duration-remote-in: 1400ms;
$animation-duration-remote-out: 400ms;
$animation-duration-remote-button-highlight: 800ms;


.screen-remote {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;

  .remote {
    position: absolute;
    bottom: -196px - 300px;
    left: 50%;
    margin-left: -102px;
    width: 204px;
    height: 658px;
    opacity: 0;
    transition: bottom $animation-duration-remote-out $animation-function-remote-out,
      opacity $animation-duration-remote-out $animation-function-remote-out;

    &.to-top {
      bottom: -183px;
      opacity: 1;
      transition: bottom $animation-duration-remote-in $animation-function-remote-in,
        opacity $animation-duration-remote-in $animation-function-remote-in;

      .inner {
        transform: scale(1,1);
        transition: transform $animation-duration-remote-in $animation-function-remote-in;
      }

      &.small {
        bottom: -224px;

        .inner {
          transform: scale(0.9,0.9);
        }
      }
    }

    .inner {
      position: relative;
      width: 100%;
      height: 100%;
      transform: scale(0.9,0.9);
      transform-origin: top center;
      transition: transform $animation-duration-remote-out $animation-function-remote-out;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: opacity 300ms ease-in-out;
    }
    button {
      position: absolute;
      top: 346px;
      left: 78px;
      width: 46px;
      height: 46px;
      padding: 0;
      margin: 0;
      background: none;
      border: none;
      outline: none;
      overflow: hidden;

      &.animate {
        .highlight {
          transition: transform $animation-duration-remote-button-highlight linear;
          transform: translateX(43px);
        }
      }

      div {
        position: relative;
        width: 100%;
        height: 100%;
      }
      .bg {
        position: absolute;
        z-index: 2;
        top: -2px;
        left: -2px;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
      }
      .highlight {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 0;
        margin-top: (-.5 * 34px);
        width: 43px;
        height: 34px;
        transform: translateX(-43px);
      }
    }
  }

  .att-lockup {
    position: absolute;
    top: calc(50% - (.5 * 90px));
    left: 50%;
    margin-left: (-.5 * 249px);
    width: 249px;
    height: 90px;
    opacity: 0;
    transform-origin: top center;
    transition: top $animation-duration-quicktour $animation-function-quicktour,
      transform $animation-duration-quicktour $animation-function-quicktour,
      opacity 1000ms ease-in-out;

    &.fade-in {
      opacity: 1;

      img {
        &.logo {
          opacity: 1;
        }
        &.with {
          transition-delay: 1000ms;
          opacity: 1;
        }
        &.ga {
          transition-delay: 1500ms;
          opacity: 1;
        }
      }
    }
    &.fade-in-synced {
      opacity: 1;

      img {
        &.logo {
          opacity: 1;
        }
        &.with {
          opacity: 1;
        }
        &.ga {
          opacity: 1;
        }
      }
    }
    &.to-top {
      top: 35px;

      &.small {
        top: 25px;
        transform: scale(0.88);
      }
    }

    .inner {
      position: relative;
      width: 100%;
      height: 100%;
    }

    img {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin: 0;
      opacity: 0;
      transition: opacity 1000ms ease-in-out;
    }
  }

  .legal {
    position: absolute;
    bottom: 0;
    width: 75px;
    font-size: 8.5px;
    color: #fff;
    font-family: 'ATT Aleck Cd';
    font-weight: 400;
    font-style: normal;
    bottom: 86px;
    
    opacity: 0;
    transition: opacity 1000ms ease-in-out;

    &.google {
      right: 50%;
      margin-right: 96px;
      text-align: right;
    }
    &.simulation {
      left: 50%;
      text-align: left;
      margin-left: 96px;
    }

    &.fade-in {
      opacity: 1;
    }
  }

}

@keyframes anim-ga-button-highlight {
  0%   {
    transform: translateX(-43px);
  }
  100% {
    transform: translateX(43px);
  }
}
