@import './Tooltip.scss';

$text-animation-delay: $tooltip-animation-duration-in;
$text-animation-duration: 300ms;
$text-animation-easing: ease-in-out;

.tooltip.getstarted {
  &.visible {
    .text {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  .text {
    position: relative;
    padding: 25px 20px;
    font-size: 17px;
    color: #FFF;
    opacity: 0;
    transform: translateY(10px);
    transition: opacity $text-animation-duration $text-animation-easing,
      transform $text-animation-duration $text-animation-easing;
    transition-delay: $text-animation-delay;
  }
}
