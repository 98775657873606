// Header
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background: #71CFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  .arrow {
    display: block;
    height: 20px;
    width: 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
    background: url('../assets/images/Arrow.png') no-repeat center;
    background-size: contain;

    @media (orientation: landscape) {
      left: 50px;
    }
  }
  
  .animated {
    position: absolute;
    right: 0px;
    height: 30px;
    width: 47%;
  }

  .header-container {
    width: 53%;
    position: absolute;
    height: 60px;
    left: 0;
  }

  h2 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin: 0;

    &.stationary {
      left: 82px;
      
      @media (orientation: landscape) {
        left: 73%;
      }
    }
    
    &.animating {
      left: -59px;
    }
  }
}

#target {
  width: 100%;
  height: 100%;
  position: absolute;
}

// Look for Cta Popup
#gradient {
  position: absolute;
  height: 160px;
  width: 100%;
  bottom: 0;
  background: #71CFFF;
  pointer-events: none;
  transition: 0.4s linear;

  h2 {
    width: 60%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 42px;
  }

  &.hide {
    display: none;
    transform: translateY(160px); 
  }
}

$modalHeight: 260px;

.target-found {
  width: 100%;
  height: $modalHeight;
  display: flex;
  position: absolute;
  bottom: 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: rgba(255,255,255, .8);
  transition: all 0.5s ease;
  transform: translateY($modalHeight);
  
  @media (orientation: landscape) { 
    height: 180px;
  }
  
  &.slide-in {
    transform: translateY(0);

    .content {
      opacity: 1;
    }
  }

  .simple-close {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 1000;

    &:before, &:after {
      content: '';
      width: 100%;
      height: 4px;
      position: absolute;
      background: #0066B3;
      left: 50%;
      top: 50%;
      border-radius: 10px;
    }
    
    &:before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &:after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  .content {
    position: absolute;
    bottom: 32px;
    text-align: center;
    opacity: 0;
    transition: opacity 0.3s linear 0.2s;

    h1 {
      font-size: 3rem;
      text-transform: uppercase;
      font-family: 'ATT Aleck Sans';
      font-weight: 900;
      font-style: italic;
      margin: 30px auto;

      @media (orientation: landscape) { 
        font-size: 2rem;
        margin: -5px auto;
      }
    }

    p {
      color: #000;
      margin: -10px auto 10px;
      
      @media (orientation: landscape) { 
        margin: 20px auto -10px;
      }
    }
    .button {
      @media (orientation: landscape) { 
        margin-bottom: 0px;
      }
    }
  }
}

#target-indicator {
  width: 250px;
  top: 130px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  @media (orientation: landscape) { 
    width: 150px;
    top: 80px;
  }
}

.zIndex {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
}

#legal {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  text-transform: uppercase;
  font-size: .625rem;
  color: #0057B8;
  padding: 10px 50px;
  font-family: 'ATT Aleck Cd';
}

#legal, .legal {
  &:focus {
    outline: none;
  }
}

.legal-modal {
  -webkit-overflow-scrolling: touch;
  position: absolute;
  background: #3DA3D5;
  z-index: 1000;
  padding: 40px;
  font-family: 'ATT Aleck Cd';
  text-align: left;
  overflow: auto;
  height: 100%;
  
  h3 {
    text-align: left;
    font-size: 1rem;
    font-weight: 800;
  }

  p {
    font-size: 1rem;
    font-weight: 300;
    padding-bottom: 80px;
  }
}

.close-button {
  height: 30px;
  width: 30px;
  position: absolute;
  top: 20px;
  right: 20px;

  &:before, &:after {
    content: '';
    width: 2px;
    height: 100%;
    background: #fff;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    transform-origin: center;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
}