$tooltip-animation-duration-in: 600ms;
$tooltip-animation-easing-in: cubic-bezier(0.935, -0.005, 0.655, 1.275);
$tooltip-animation-duration-out: 300ms;
$tooltip-animation-easing-out: ease-in-out;

$title-animation-delay: $tooltip-animation-duration-in;
$title-animation-duration: 300ms;
$title-animation-easing: ease-in-out;

$button-animation-delay: $title-animation-delay;
$button-animation-delay-seperation: 200ms;
$button-animation-duration: 600ms;
$button-animation-opacity-duration: 600ms;
$button-animation-easing: ease-in-out;

.tooltip-container {
  position: absolute;
  z-index: 1000;
  bottom: 153px;
  left: 50%;
  width: 276px;
  margin-left: -138px;
  pointer-events: none;

  &.visible {
    pointer-events: auto;
  }
}
.tooltip {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0 13px;
  box-sizing: border-box;
  text-align: center;
  background-color: #4D9DFF;
  border-radius: 14px;
  transform-origin: center bottom;
  transform: scale(0,0);

  &.transition-in {
    transition: transform $tooltip-animation-duration-in $tooltip-animation-easing-in;
  }
  &.transition-out {
    transition: transform $tooltip-animation-duration-out $tooltip-animation-easing-out;
  }
  &.visible {
    transform: scale(1,1);

    .title {
      opacity: 1;
      transform: translateY(0px);
    }
    button {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  .arrow {
    position: absolute;
    bottom: -10px;
    left: 127px;
    width: 20px;
    height: 20px;
    background-color: #4D9DFF;
    transform-origin: center center;
    transform: rotate(45deg);
  }


  .title {
    position: relative;
    padding: 16px 0;
    margin: 0;
    font-size: 17px;
    color: #FFF;
    opacity: 0;
    transform: translateY(10px);
    transition: opacity $title-animation-duration $title-animation-easing,
      transform $title-animation-duration $title-animation-easing;
    transition-delay: $title-animation-delay;
  }

  .buttons {
    position: relative;
    padding: 0 0 13px;

    &.scrollable{
      padding: 10px 1px 10px 0px;

      button:last-child{
        margin-bottom: 0;
      }
    }

    &.split-2 {
      display: flex;
      flex-direction: row;
      
      button {
        width: calc(50% - 5px);

        &:first-child {
          margin-right: 5px;
        }
        &:last-child {
          margin-left: 5px;
        }
      }
    }
  }
  button {
    position: relative;
    display: block;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    margin: 0 0 10px;
    background: transparent;
    border: none;
    outline: none;
    border: 1px solid #fff;
    border-radius: 5px;
    opacity: 0;
    font-size: 13px;
    color: #FFF;
    white-space: nowrap;
    text-align: center;
    transform: translateY(-10px);
    transition: transform $button-animation-duration $button-animation-easing,
      opacity $button-animation-opacity-duration $button-animation-easing,
      background-color $button-animation-opacity-duration $button-animation-easing;

    @for $i from 0 through 10 {
      $delay: $button-animation-delay + ($button-animation-delay-seperation * $i);

      &:nth-child(#{$i}) {
        transition-delay: $delay;
      }
    }

    &.seen {
      background: rgba(255,255,255,0.18);
    }
  }
}
