#cameraPermissionsErrorAndroid {
  padding: 2vh 0 15vh;

  .ar-loading-screen {
    background-size: cover;
  }
}

#cameraPermissionsErrorApple {
  padding: 0;
}

.absolute-fill {
  height: 100vh !important;
}

#topSwoosh {
  width: 80px;
  background-image: url('../assets/images/TopSwoosh.png');
  background-size: contain;
  height: 90px;
  position: absolute;
  top: 0;
  right: 0;
}

#bottomSwoosh {
  width: 200px;
  background-image: url('../assets/images/BottomSwoosh.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  height: 90px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.ar-loading-screen {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  overflow: auto;
  width: 100vw;
  height: 100%;
  background-color: #70cfff;

  .image {
    height: auto;
    width: 200px;
    height: 250px;
    margin: -10px auto 20px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
                           
    @media (orientation: landscape) {
      height: 110px;
      width: auto;
    }
  }

  h1 {
    font-size: 3rem;
  }

  span {
    display: block;
    margin-bottom: 30px;
    font-family: 'ATT Aleck Sans';
    color: #0075BE;
    font-size: 1rem;
    margin: 18px auto 70px;

    &.highlight {
      margin: 0 auto;
    }
    
    @media (orientation: landscape) {
      font-size: 18px;
      margin: 10px auto;
    }
  }

  p {
    font-family: 'ATT Aleck Sans';
    color: #fff;
    font-size: 1rem;
    padding: 0 50px;
    margin: 20px 0px 0px 0px;
    text-align: center;
  }

  .chrome-instruction, .camera-instruction-block {
    margin: 0;
  }

  .camera-instruction-button {
    margin: 10px 0;
    padding: 1vh 3vh;
  }

  .eighth-wall-logo {
    width: 69px;
    height: auto;
    
    @media (orientation: landscape) {
      width: 80px;
    }
  }

  &.error {
    .loading-animation {
      top: 25%;

      @media (orientation: landscape) {
        top: 32%;
      }
    }
    .text {
      width: 90%;
      margin: 0px auto 20px;
      @media (orientation: landscape) {
        margin: 10px auto;
      }

      h1 {
        font-weight: 800;
        text-transform: uppercase;
        margin-bottom: 15px;
        font-size: 2.25rem;
      }
      
      p {
        position: relative;
        margin: 2.5px;
        font-size: 1.375rem;
        letter-spacing: 0.13;
      }

      .button {
        margin: 30px auto 0;
        text-align: center;;
      }

      .camera-instruction-button {
        background: transparent;
        box-shadow: none;
        font-weight: 800;
        font-size: 1.25rem;
        margin: 5px auto 10px;
      }
    }
  }
}

.error-page {
  background-image: url('../assets/images/ErrorBG.jpg');
  background-size: cover;
}

