.errorPage {
  background-image: url('../../../assets/images/ErrorBG.jpg');
  background-size: cover;
  height: 100vh;
  font-family: 'ATT Aleck Sans';
  color: #fff;
  overflow: hidden;
}

.errorPage .error-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -30%;
}


.errorPage img {
  z-index: 1;
  width: 750px;
  margin-left: -17%;
}

.errorPage p {
  font-size: 2rem;
  line-height: 1.36;
  letter-spacing: 0.13px;
  text-align: center;
  padding: 0 20%;
}

@media (max-width: 768px) {
  .errorPage {
    background-image: url('../../../assets/images/ErrorBGVertical.jpg');
    background-position: bottom;
  }

  .errorPage .container {
    height: 53%;
    margin-top: -50%;
  }

  .errorPage img {
    width: 65%;
    top: 40%;
    left: 43%;
  }
  .errorPage p {
    font-size: 1.3rem;
    bottom: 24%;
  }
}
