// ----------------- HUD ----------------- //
.hud {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0 auto;

  &:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 110px;
    width: 100%;
    background: #23a7e0;
    background: linear-gradient(rgba(#23a7e0, 1) 15%, rgba(#23a7e0, 0));
  }

  &.horizontal-hud{
    &:before{
      height: 85px;
    }
    .hud-left{
      display: flex;
      margin-top: 5px;
      padding-left: 40px;
    }
    .hud-right{
      padding-right: 40px;
    }
  }


  .hud-header{
    position: relative;
    z-index: 3;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 4px;
  }

  .hud-middle, .hud-right{
    display: flex;
    align-items: center;
  }
  .hud-right{
    justify-content: flex-end;
  }


  .streak-container{
    width: 140px;
  }

  .track-container{
    width: 110px;
    margin-right: 5px;

    .track-map{
      top: 5px;
      position: relative;
    }
    .track-score{
      position: absolute;
      width: 100%;
      height: 100%;
      top: -8%;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      span{
        font-family: ATT Aleck Cd;
        font-size: 22px;
        color: #010101;
      }
      img{
        margin-left: 3px;
        max-width: 20px;
      }
    }
  }

  .timer-container{
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    img{
      width: auto;
      height: 32px;
      margin-right: 3px;
    }
    span{
      font-family: ATT Aleck Cd;
      font-size: 30px;
      color: #010101;
    }
  }


  .scorebar{
    min-width: 75px;
    display: inline-block;
    border: 10px solid #010101;
    border-radius: 8px;
    background-color: #ffffff;
    text-align: center;
      
    span{
      padding: 4px;
      font-family: ATT Aleck Cd;
      font-size: 36px;
      line-height: 1;
      color: #010101;
    }
  }

  .button-container{
    text-align: right;
    width: 140px;
    padding: 0 5px;
    img{
      width:60px;
    }
  }
  
  // .controls-container {
  //   position: absolute;
  //   width: 50%;
  //   @media (orientation: landscape) {
  //     left: 12px;
  //   }
  // }
  
  // @media (orientation: landscape) {
  //   position: relative;
  //   height: 100%;

  //   .row {
  //     margin-bottom: 0;
  //   }
  // }
  // .row {
  //   display: flex;
  //   justify-content: flex-start;
  //   flex-direction: row;
  //   align-items: center;
  //   margin-bottom: -25px;
  //   height: 70px;

  //   @media (orientation: landscape) {
  //     height: 86px;
  //   }

  //   p {
  //     font-family: 'ATT Aleck Cd';
  //     font-size: 38px;
  //     margin: 3px 0 0 -10px;
      
  //     @media (orientation: landscape) {
  //       @supports (-webkit-overflow-scrolling: touch) {
  //         margin: -6px 0 0 -10px;
  //       }
  //     }
  //   }

  //   #timer {
  //     width: 60px;
  //     height: 60px;
  //   }

  //   #health, #streak {
  //     width: 170px;
  //     height: auto;
  //   }
  // }
  
  .reposition-container {
    position: absolute;
    z-index: 3;
    bottom: 5px;
    right: 0px;
    width: 86px;
    height: 90px;

    #reposition {
      width: 100%;
      height: 100%;
    }

    @media (orientation: landscape) {
      right: 10px;
    }
  }

  // .pause-container {
  //   position: absolute;
  //   z-index: 3;
  //   display: flex;
  //   z-index: 3;
  //   flex-direction: row;
  //   align-items: center;
  //   justify-content: flex-end;
  //   top: -25px;
  //   right: 10px;
  //   width: 80%;
    
  //   @media (orientation: landscape) {
  //     top: -10px;
  //     right: 32px;
  //   }

  //   .score {
  //     font-size: 38px;
  //     color: #fff;
  //     font-weight: bold;
  //     text-align: right;
  //     text-shadow: 0px 0px 6px rgba(0, 39, 75, 0.35);
  //   }

  //   .pause-button {
  //     margin: 10px 0 10px 10px;
  //     width: 50px;
  //     height: 50px;
  //     border-radius: 50%;
  //     background: #fff;
  //     position: relative;
  //     z-index: 100000;
  //     box-shadow: 0px 0px 6px rgba(0, 39, 75, 0.35);

  //     @media (orientation: landscape) {
  //       margin-top: 25px;
  //     }

  //     @media (max-height: 300px) {
  //       margin-top: 28px;
  //     }
      
  //     &:before, &:after {
  //       transition: 0.25s linear;
  //       content: '';
  //       width: 8px;
  //       height: 23px;
  //       background: $blue;
  //       display: block;
  //       position: absolute;
  //       top: 50%;
  //       left: 50%;
  //       transform-origin: center;
  //       border-radius: 1px;
  //     }

  //     &:before {
  //       transform: translate(41%, -50%);
  //     }
  //     &:after {
  //       transform: translate(-140%, -50%);
  //     }
  //   }
  // }

  // .close {
  //   position: absolute;
  //   z-index: 999;
  //   right: 10px;
  // }

  // .swipe-to-throw {
  //   position: absolute;
  //   bottom: 180px;
  //   left: 50%;
  //   transform: translateX(-50%);
  //   pointer-events: none;
  //   transition: .3s;

  //   @media only screen and (min-width: 414px) {
  //     bottom: 210px;
  //   }

  //   &.hide{
  //     opacity: 0;
  //   }

  //   p {
  //     font-size: 12px;
  //     font-weight: 800;
  //     text-transform: uppercase;
  //   }

  //   img {
  //     width: 100px;
  //     margin: 0 auto;
  //     display: block;
  //   }
  // }

  .gaming-arrows {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    pointer-events: none;

    .arrows {
      position: absolute;
      width: 7vw;
      opacity: 0.75;
      top: 20%;
      transform: translateY(-50%);

      &.down {
        right: 40px;
        transform-origin: center center;
      }
      
      &.up {
        transform: translateY(-51%) rotate(180deg);
        left: 40px;
      }
    }
  }
}